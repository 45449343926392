<template>
  <div class="maintain-page">
    <div class="maintain-icon" />
    <div class="maintain-tip">{{ maintainTxt }}</div>
  </div>
</template>

<script>
export default {
  name: 'Maintain',
  computed: {
    maintainTxt () {
      return this.$store.state.appConfig.maintainTxt
    }
  }
}
</script>

<style lang="scss" scoped>
.maintain-page {
  height: 100vh;
  text-align: center;
  overflow: hidden;
  &:before {
    content: '';
    display: block;
    height: 6px;
    border-radius: 5px;
    margin: 8px;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.1);
  }
  .maintain-icon {
    width: 2rem;
    height: 2rem;
    margin: 25vh auto 0;
    background: url('./assets/icon-maintain.png') no-repeat center center;
    background-size: contain;
  }
  .maintain-tip {
    font-size: .20rem;
    color: #bdbdbd;
    padding: .20rem;
    line-height: 2em;
    text-shadow: 0 3px 4px rgba(0, 0, 0, 0.05);
  }
}
</style>
